import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { applyOpenGraphThumbnailSize, classNames } from '@shapeable/utils';
import { Page } from '@shapeable/gesda-types';
import { ComponentPropsProvider, ContentEntityRichSubtitle, ContentEntityRichTitle, EntityCarousel, EntityGrid } from '@shapeable/ui';
const cls = classNames('explorer-home-banner-layout');

// -------- Types -------->

// -------- Props -------->

export type ExplorerHomeBannerLayoutProps = Classable & HasChildren & { 
  entity?: Page;
};

export const ExplorerHomeBannerLayoutDefaultProps: Omit<ExplorerHomeBannerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const CarouselStyles = breakpoints({
  base: css`
    overflow: hidden;
    .shp--image-entity-background,
    .shp--image-entity-background-slideshow {
      overflow: visible;
    };
  `,
  desktopDevice: css`
    border-radius: 0 0 ${theme.UNIT(3)} ${theme.UNIT(3)};
  `,
});

const BannerTitleStyles = breakpoints({
  base: css`
    font-size: 2em;
    font-weight: 300;
    line-height: 1.25em;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    font-weight: 300;
    margin-bottom: ${theme.UNIT(2)};
    line-height: 1.10;
    font-size: 1.8em;

    strong {
      color: ${theme.COLOR('light')};
    }
  `,
});

const SubtitleStyles = breakpoints({
  base: css`
    font-size: 1.2em;
    
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Carousel: styled(EntityCarousel)`${CarouselStyles}`,
    BannerTitle: styled.span`${BannerTitleStyles}`,
    Title: styled(ContentEntityRichTitle)`${TitleStyles}`,
      Subtitle: styled(ContentEntityRichSubtitle)`${SubtitleStyles}`,
};

export const ExplorerHomeBannerLayout: Shapeable.FC<ExplorerHomeBannerLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const { cardLinks = [], bannerLinks = [] } = entity;
  
  const applyCardThumbnails = applyOpenGraphThumbnailSize('card');
  const featurePagesTrimmed = cardLinks.slice(0, 4).map(applyCardThumbnails);

  const hasLinkCards = !!featurePagesTrimmed.length;
 
  return (
    <ComponentPropsProvider value={{ 
      EntityBanner: {
        showOverlay: true,
        variant: 'image',
        customAspectRatio: { base: 4 / 3, landscape: 21 / 9, tablet: 21 / 9 },
      }}}>
    <My.Container className={className}>
      <My.Carousel
        items={bannerLinks}
        transitionType='fade'
        slideControls={{ variant: 'line', showNavigationButtons: true }}
      />
    </My.Container>
    </ComponentPropsProvider>
  )
};

ExplorerHomeBannerLayout.defaultProps = ExplorerHomeBannerLayoutDefaultProps;
ExplorerHomeBannerLayout.cls = cls;