import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { applyOpenGraphThumbnailSize, classNames } from '@shapeable/utils';
import { Badge, ComponentPropsProvider, EntityCard, EntityCardDefaultProps, EntityCardProps, PersonCardImage, PersonCardMeta, useLang } from '@shapeable/ui';
import { Person } from '@shapeable/gesda-types';
import { toString } from 'lodash';

import { useCurrentEdition } from '../../hooks/use-current-edition';
const cls = classNames('person-card');

// -------- Types -------->

// -------- Props -------->

export type PersonCardProps = Omit<EntityCardProps, 'entity'> & { 
  entity?: Person;
};

export const PersonCardDefaultProps: Omit<PersonCardProps, 'entity'> = {
  ...EntityCardDefaultProps,
  showColorStrip: true,
  showDescription: false,
  showTypeHeader: false,
  useEntityColorForBackground: true,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    // quick-fix for chevron color, until button is moved to color token system
    ${theme.FILL('dark')};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(EntityCard)<ContainerProps>`${ContainerStyles}`,
};

export const PersonCard: Shapeable.FC<PersonCardProps> = (props) => {
  const { className, ...cardProps } = props;

  const t = useLang();
  const entity: Person = applyOpenGraphThumbnailSize('bubbleLarge')(props.entity);
  const currentEdition = useCurrentEdition();
  return (
    <ComponentPropsProvider value={{
      Badge: {
        colors: {
          background: entity.color?.value
        },
      }
    }}>
    <My.Container 
      {...cardProps}
      className={cls.name(className)}
      entity={entity} 
      showColorStrip={false}
      useEntityColorForBackground={currentEdition?.name === entity.badge}
      showImage={false}
      head={
        <PersonCardImage entity={entity} />
      }
      meta={
        <PersonCardMeta entity={entity} />
      }
    />
    </ComponentPropsProvider>
  )
};

PersonCard.defaultProps = PersonCardDefaultProps;
PersonCard.cls = cls;