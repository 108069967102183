module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FRY3KNQ963"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9678f578737d8f0d7f971b62a5e59837"},
    },{
      plugin: require('../node_modules/@shapeable/gatsby-theme-shapeable-web/gatsby-browser.js'),
      options: {"plugins":[],"fonts":["Aleo:300,300i,400,400i,700","Montserrat:300,300i,500,500i,700"],"entities":{"Post":{},"FeedEntry":{},"Citation":{},"OrganisationType":{},"Economy":{},"Person":{},"Video":{}},"siteMetadata":{"title":"","description":"","image":"","video":"","twitter":"","threads":"","linkedin":"","facebook":"","flickr":"","tiktok":"","youtube":"","instagram":"","name":"","logo":"","titleTemplate":"%s - %s","openGraphTitleTemplate":"%s - %s"}},
    }]
