import React, { useState } from 'react';
import { Classable, Dictionary, HasChildren, Shapeable } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { classNames, onSubmitNetlifyForm } from '@shapeable/utils';
import { breakpoints, theme } from '@shapeable/theme';
import EmailValidator from 'email-validator';
import { filter, includes, trim, without } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import validUrl from 'valid-url';
import { ErrorList, Field, FormButton, FormLoader, Input, LinearNavigationItem, Select, SelectOption, TextArea, useActiveEntity, useContributeForm, useLinearNavigation, usePage, usePages, useSiteRecaptchaKey } from '@shapeable/ui';
import { Page } from '@shapeable/gesda-types';

const cls = classNames('contribute-form');

// -------- Types -------->

export type ContributeFormProps = Classable & HasChildren & {
}

export const ContributeFormDefaultProps: ContributeFormProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    min-height: 200px;
  `,
});

const FieldStyles = breakpoints({
  base: css`
    
  `,
});

const SelectStyles = breakpoints({
  base: css`
    width: 100%;
    font-size: ${theme.FONT_SIZE(14)};
  `,
});

const FormStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(3)};
  `,
});



const TextAreaStyles = breakpoints({
  base: css`
    width: 100%;
    height: 150px;
    color: ${theme.COLOR('strong')};
  `,
});



const InputStyles = breakpoints({
  base: css`
    width: 100%;
    color: ${theme.COLOR('strong')};
  `,
});

const SubmitButtonStyles = breakpoints({
  base: css`
    width: 100%;
    margin-top: ${theme.UNIT(2)};
  `,
});


const OverlayStyles = breakpoints({
  base: css`
    z-index: 100;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  `,
});


const LoaderStyles = breakpoints({
  base: css`
    ${OverlayStyles};
  `,
});

const ThankyouStyles = breakpoints({
  base: css`
    ${OverlayStyles};
    background: rgba(0,0,0,0.9);
    padding: ${theme.UNIT(4)};
    width: 100%;
    flex-grow: 1;
    color: ${theme.COLOR('light')};
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    p {
      margin: ${theme.UNIT(4)};
    }
  `,
});

const ThankyouMessageStyles = breakpoints({
  base: css`
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 1.25em;
  `,
});


const DoneButtonStyles = breakpoints({
  base: css`
    ${SubmitButtonStyles};
    
  `,
});

const ErrorsStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('red')};
    background: #EFEEEE;
    padding: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(4)};
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(14)};
  `,
});

// -------- Components -------->

const PAGE = 'page';
const TYPE = 'type';
const DETAILS = 'details';
const LINK = 'link';
const NAME = 'name';
const EMAIL = 'email';
const COMPANY = 'company';
const URL = 'url';
const STAKEHOLDER_TYPE = 'stakeholderType';

const FORM_NAME = 'contribute';

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Loader: styled(FormLoader)`${LoaderStyles}`,
    Thankyou: styled.div`${ThankyouStyles}`,
      ThankyouMessage: styled.div`${ThankyouMessageStyles}`,
      DoneButton: styled(FormButton).attrs({ type: 'button' })`${DoneButtonStyles}`,

    Form: styled.form`${FormStyles}`,
      PageField: styled(Field).attrs({ id: PAGE, label: 'Commenting On:' })`${FieldStyles}`,
        Page: styled(Select).attrs({ id: PAGE, placeholder: 'Select a Page' })`${SelectStyles}`,
      TypeField: styled(Field).attrs({ id: TYPE, label: 'Your Contribution:' })`${FieldStyles}`,
        Type: styled(Select).attrs({ id: TYPE, placeholder: 'Contribution Type' })`${SelectStyles}`,
      DetailsField: styled(Field).attrs({ id: DETAILS })`${FieldStyles}`,
        Details: styled(TextArea).attrs({ id: DETAILS, placeholder: 'Details (Required)' })`${TextAreaStyles}`,
      LinkField: styled(Field).attrs({ id: LINK })`${FieldStyles}`,
        Link: styled(Input).attrs({ id: LINK, placeholder: 'Link (if applicable)' })`${InputStyles}`,
      
      NameField: styled(Field).attrs({ id: NAME, label: 'Your Details:' })`${FieldStyles}`,
        Name: styled(Input).attrs({ id: NAME, placeholder: 'Name (Required)' })`${InputStyles}`,
      EmailField: styled(Field).attrs({ id: EMAIL })`${FieldStyles}`,
        Email: styled(Input).attrs({ id: EMAIL, placeholder: 'Email' })`${InputStyles}`,
      CompanyField: styled(Field).attrs({ id: COMPANY })`${FieldStyles}`,
        Company: styled(Input).attrs({ id: COMPANY, placeholder: 'Company' })`${InputStyles}`,
      UrlField: styled(Field).attrs({ id: URL })`${FieldStyles}`,
        Url: styled(Input).attrs({ id: URL, placeholder: 'Company Website URL' })`${InputStyles}`,
      StakeholderTypeField: styled(Field).attrs({ id: STAKEHOLDER_TYPE })`${FieldStyles}`,
        StakeholderType: styled(Select).attrs({ id: STAKEHOLDER_TYPE, placeholder: 'Stakeholder Type' })`${SelectStyles}`,

    Errors: styled(ErrorList)`${ErrorsStyles}`,

    SubmitButton: styled(FormButton)`${SubmitButtonStyles}`,
};

export const ContributeForm: Shapeable.FC<ContributeFormProps> = (props) => {
  const { className } = props;
  const form = useContributeForm();

  const { values, patchValues } = form;
  const [ token, setToken ] = useState('');
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ errorFields, setErrorFields ] = useState<string[]>([]);
  
  const pages = usePages();
  const currentPage = useActiveEntity();
  // const { items } = useLinearNavigation();
  
  const ref = form.useClickOutside();
  
  const pageOption = (item: Page) => {
    if (!item) {
      return null;
    }

    const label = filter([item.name]).join('. ');

    return {
      label,
      value: item.path,
    }
  };

  const pageOptions: SelectOption[] = pages.map(pageOption);
  let pageDefaultOption = pageOption(pages.find(item => item.path === currentPage?.__path));

  if (!pageDefaultOption) {
    // if the page is not in the dropdown, just append it to the end
    pageDefaultOption = { label: `${currentPage.name}`, value: currentPage?.__path };
    pageOptions.push(pageDefaultOption);
  }

  const typeDefaultOption = {
    value: 'Comment',
    label: 'Comment',
  };


  const typeOptions: SelectOption[] = [
    typeDefaultOption,
    {
      value: 'Expert Suggestion',
      label: 'Expert Suggestion',
    },
    {
      value: 'Feedback',
      label: 'Feedback',
    },
    {
      value: 'Product or Solution',
      label: 'Product or Solution',
    },
    {
      value: 'Research Insight',
      label: 'Research Insight',
    },
  ];

  const stakeholderTypeOptions: SelectOption[] = [
    {
      value: 'Academic',
      label: 'Academic',
    },
    {
      value: 'Diplomacy',
      label: 'Diplomacy',
    },
    {
      value: 'Citizen',
      label: 'Citizen',
    },
    {
      value: 'Impact',
      label: 'Impact',
    },
  ];

  
  const formState: Dictionary<any> = { 
    ...values, 
    page: (values[PAGE] || pageDefaultOption),
    type: (values[TYPE] || typeDefaultOption), 
  };

  const onChange = (name: string) => (value: string | SelectOption) => { patchValues({ [name]: value }); setErrors([]); setErrorFields(without(errorFields, name)); };

  const onSubmit = async(event: React.FormEvent) => {
    event.preventDefault();
    
    const e = [];
    const ef = [];
    
    if (!token) {
      errors.push('Please check the I\'m not a robot checkbox to validate you are human');
    }

    if (trim(formState[NAME]) === '') {
      e.push('Please enter your name.');
      ef.push(NAME);
    }

    if (trim(formState[DETAILS]) === '') {
      e.push('Please describe your contribution in the Details field.');
      ef.push(DETAILS);
    }

    if (trim(formState[EMAIL]) !== '' && !EmailValidator.validate(formState[EMAIL])) {
      e.push('Please check that your email address is VALID');
      ef.push(EMAIL);
    }

    if (trim(formState[LINK]) !== '' && !validUrl.isWebUri(formState[LINK])) {
      e.push('Please provide a VALID URL in the link field');
      ef.push(LINK);
    }

    if (trim(formState[URL]) !== '' && !validUrl.isWebUri(formState[URL])) {
      e.push('Please provide a VALID URL in the Company Website URL field');
      ef.push(URL);
    }

    setErrorFields(ef);
    setErrors(e);

    if (e.length) {
      return false;
    }

    const data = { ...formState, 'g-recaptcha-response': token };
    form.startSubmit();
    const response = await onSubmitNetlifyForm(FORM_NAME, currentPage.__path, data);
    setToken('');
    form.endSubmit();
  };

  const onCaptchaChange = (token: any) => {
    setToken(token);
  };

  const isSubmitDisabled = !token;
  const hasErrors = !!errors.length;

  const SITE_RECAPTCHA_KEY = useSiteRecaptchaKey();

  return (
    <My.Container ref={ref} className={cls.name(className)}>
      {
        form.isSubmitting && 
        <My.Loader>Please wait...</My.Loader>
      }
      {
        (form.isSubmitted) ?
        <My.Thankyou>
          <My.ThankyouMessage>
          <p>
          We've received your feedback.
          </p>
          <p>
          Thanks for your contribution!
          </p>
          </My.ThankyouMessage>
          <My.DoneButton onClick={() => { form.reset(); form.hide(); }}>Done</My.DoneButton>
        </My.Thankyou> :
        <My.Form name={FORM_NAME} onSubmit={onSubmit} method="post" data-netlify="true" data-netlify-recaptcha="true">
          <input type="hidden" name="form-name" value={FORM_NAME} />
          <My.PageField>
            <My.Page
              isClearable={false}
              onChange={onChange(PAGE)}
              value={formState[PAGE]}
              options={pageOptions}
              
            />
          </My.PageField>

          <My.TypeField>
            <My.Type 
              isClearable={false}
              onChange={onChange(TYPE)}
              value={formState[TYPE]}
              placeholder="Contribution Type"
              options={typeOptions}
            />
          </My.TypeField>

          <My.DetailsField>
            <My.Details hasError={includes(errorFields, DETAILS)} onChange={onChange(DETAILS)}>{formState[DETAILS] || ''}</My.Details>
          </My.DetailsField>

          <My.LinkField>
            <My.Link hasError={includes(errorFields, LINK)} onChange={onChange(LINK)} value={formState[LINK] || ''} />
          </My.LinkField>

          <My.NameField>
            <My.Name hasError={includes(errorFields, NAME)} onChange={onChange(NAME)} value={formState[NAME] || ''} />
          </My.NameField>

          <My.EmailField>
            <My.Email hasError={includes(errorFields, EMAIL)} onChange={onChange(EMAIL)} value={formState[EMAIL] || ''} />
          </My.EmailField>

          <My.CompanyField>
            <My.Company hasError={includes(errorFields, COMPANY)} onChange={onChange(COMPANY)} value={formState[COMPANY] || ''} />
          </My.CompanyField>

          <My.UrlField>
            <My.Url hasError={includes(errorFields, URL)} onChange={onChange(URL)} value={formState[URL] || ''} />
          </My.UrlField>

          
          <My.StakeholderTypeField>
            <My.StakeholderType 
              onChange={onChange(STAKEHOLDER_TYPE)}
              options={stakeholderTypeOptions}
              value={formState[STAKEHOLDER_TYPE]} 
            />
          </My.StakeholderTypeField>
          {
            SITE_RECAPTCHA_KEY && 
            <ReCAPTCHA
              sitekey={SITE_RECAPTCHA_KEY}
              onChange={onCaptchaChange}
            />
          }
          {
            hasErrors && 
            <My.Errors items={errors}>
            Sorry, we need a bit more information:
            </My.Errors>
          }
          <My.SubmitButton disabled={isSubmitDisabled} />
        </My.Form>
      } 
      
    </My.Container>
  )
};

ContributeForm.defaultProps = ContributeFormDefaultProps;
ContributeForm.cls = cls;