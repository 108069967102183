import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ExplorerEntityDetailsLayout, useEntity, SiteHeaderLayout } from '@shapeable/ui';
import { RadarChart } from '@shapeable/gesda-ui';
import { ExplorerGptViewLayout } from '@shapeable/gpt';
import { ExplorerGptBannerLayout } from './explorer-gpt-banner-layout';
import { useRadarMap } from '../../hooks/use-radar-map';
import { RadarChartMain } from '../elements/radar-chart-main';
const cls = classNames('gpt-layout');

// -------- Types -------->

export type ExplorerGptLayoutProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const ExplorerGptLayoutDefaultProps: Omit<ExplorerGptLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    background: ${theme.COLOR('light')};
  `,
});

const SiteHeaderStyles = breakpoints({
  base: css`
    
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,

});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${SiteHeaderStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout).attrs(cls.attr('Layout'))`${LayoutStyles}`,
    
};

export const ExplorerGptLayout: Shapeable.FC<ExplorerGptLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { isShown } = useRadarMap();

  return (
    <My.Container className={cls.name(className)}>
      <My.Layout
        entity={entity}
        asideMaxWidth={800}
        asideHeaderProps={{ navigationProps: { showIcon: false }}}
        isAsideSticky={false}
        asideIsRevealed={isShown}

        //main
        banner={
          <ExplorerGptBannerLayout entity={entity} />
        }
        content={
          <ExplorerGptViewLayout />
        }
        dataVizMain={
          <RadarChartMain />
        }

        //aside
        dataViz={
          <RadarChart />
        }
      />
      {children}
    </My.Container>
  )
};

ExplorerGptLayout.defaultProps = ExplorerGptLayoutDefaultProps;
ExplorerGptLayout.cls = cls;